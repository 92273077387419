// Glyphicons font path
$icon-font-path:    "../fonts/";

// Grid settings
$main-sm-columns:   12;
$main-md-columns:  12;
$sidebar-sm-columns:  12;
$sidebar-md-columns: 3;
$sidebar-lg-columns: 3;

// additional breakpoints
$screen-xxs-max: 539px;
$screen-xs-min: 540px;
$grid-float-breakpoint: 992px;
$mobile-nav-breakpoint: 991px;

//Gutter
$gutter: 15px;

// Colors
$white: #fff;
$palest-grey: #f7f7f7;
$pale-grey: #f1f1f1;
$lightest-grey: #eee;
$lighter-grey: #C8C8B9;
$light-grey: #B6B797;
$dark-grey: #444;
$hover-grey: #333;

$divider: $lighter-grey;
$nav-border: $light-grey;

$category-header-link: $dark-grey;
$category-header-bg: $palest-grey;
$category-header-border: $lightest-grey;

$red: #A4241D;
$red-hover: #7F1B18;
$blue: #004554;

$brand-primary: $red;
$background: $pale-grey;

$a-hover: $palest-grey;
$hover-text: $blue;

// side navigation
$sidenav-bg: $pale-grey;
$sidenav-link: $dark-grey;
$sidenav-child-link: #797979;
$sidenav-link-hover: $red-hover;
$sidenav-link-hover-border: darken($sidenav-bg, 15%);
$sidenav-link-active: $brand-primary;



// transitions
$timing: 0.25s;
